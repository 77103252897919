import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { track } from 'utils/analytics';

interface BrochureProps {}

const Brochure: FC<BrochureProps> = () => {
  useEffect(() => {
    track('Brochure Viewed');
    navigate('/pdfs/brochure.pdf');
  }, []);

  return <></>;
};

export default Brochure;
